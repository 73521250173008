
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionSTTTracking } from "@/feature-flags/flags-stt-tracking";
import { FlagsPermissionCargoTracking } from "@/feature-flags/flags-cargo-tracking";

@Options({
  components: {}
})
export default class BulkBooking extends Vue {
  // Tabs
  get filteredTabs() {
    const tabs = [
      {
        name: "tracking-stt",
        title: this.$t("trackingCargo.trackingSTTTabTitle"),
        permission: FlagsPermissionSTTTracking.permission_stt_tracking_enable.isEnabled()
      },
      {
        name: "tracking-cargo",
        title: this.$t("trackingCargo.trackingCargoTabTitle"),
        permission: FlagsPermissionCargoTracking.permission_cargo_tracking_enable.isEnabled()
      }
    ];
    return tabs.filter((tab: any) => tab.permission);
  }

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
