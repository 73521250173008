
import { Vue, Options } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import formatDateTrackingHistory from "@/app/infrastructures/misc/common-library/FormatDateTrackingHistory";
import isMigrateFromElexys from "@/app/infrastructures/misc/common-library/IsMigrateFromElexys";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import {
  TrackingSttDetailData,
  TrackingSttListHistory
} from "@/domain/entities/TrackingStt";
import OverlayPanel from "primevue/overlaypanel";
import Book from "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue";
import PreviewImage from "../component/preview-image-pod.vue";
import router from "@/app/ui/router";
import moment from "moment";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ShipmentBookingDetail } from "@/domain/entities/ShipmentBooking";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";
import { getTokenNGen } from "@/app/infrastructures/misc/Cookies";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import debounce from "lodash/debounce";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";

@Options({
  components: {
    EmptyState,
    Skeleton,
    OverlayPanel,
    Book,
    PreviewImage
  }
})
export default class TrackingSttV1 extends Vue {
  sttNo = "";
  sttNoCache = "";
  showPreviewImage = false;
  images: string[] = [];

  get IS_REVERSE_JOURNEY() {
    return (
      this.isReverseJourneyPrefix(this.sttDetail.sttNo) ||
      this.isReverseJourneyPrefix(this.sttDetail.sttNoRef)
    );
  }

  mounted() {
    this.refs = this.$refs;
    TrackingController.initTrackingStt();
    this.initFetch();
  }

  initFetch() {
    this.sttNo = this.$route.query["stt-no"] as string;
    if (this.sttNo) {
      this.trackStt(this.sttNo);
    }
  }

  get isSenderAccount() {
    return IS_SENDER_ACCOUNT;
  }

  get showSttDetail() {
    return TrackingController.showSttDetail;
  }

  get dataProfile() {
    return AccountController.accountData.isPosAccount;
  }

  handleError() {
    TrackingController.setError(false);
  }

  formatStt(value: string) {
    return removeNewLineWhitespace(value);
  }

  trackStt = debounce(async (stt: string, isSecondCall = false) => {
    if (!this.sttNo) return;
    if (!isSecondCall) {
      this.onChangeHistoryTabs("current");
    }
    await TrackingController.trackStt({
      sttNo: stt,
      isGetSla: !IS_SENDER_ACCOUNT,
      isSecondCall,
      cache: true
    });
    this.sttNoCache = this.sttNo;
    if (!this.isError) this.getDetailShipment();

    if (IS_SENDER_ACCOUNT) GTMCommonEvent("sender_lacak_stt_click");
  }, 500);

  get isLoading() {
    return TrackingController.isLoading;
  }

  get isSecondLoading() {
    return TrackingController.isSecondLoading;
  }

  get isError() {
    return TrackingController.isError;
  }

  get errorCause() {
    return TrackingController.errorCause;
  }

  // tabs
  activeMenu = "current";
  onChangeHistoryTabs(value: string) {
    this.activeMenu = value;
    if (value === "others") {
      this.trackStt(this.sttDetail.sttNoRef, true);
    }
  }
  get tabs() {
    return [
      {
        value: "current",
        title: "Riwayat Pengiriman"
      },
      {
        value: "others",
        title: `Riwayat Pengiriman STT ${
          this.isNewStt ? "Sebelumnya" : "Pengganti"
        }`
      }
    ];
  }

  // table stt detail
  isReverseJourneyPrefix(stt: string) {
    if (!stt) return false;
    return stt.match(/^94|^89|^78|^77|^66/gi);
  }
  get isNewStt() {
    return this.isReverseJourneyPrefix(this.sttNoCache);
  }
  get sttDetail() {
    return TrackingController.trackingSttData.sttDetail;
  }
  get sttDetailTable() {
    return [TrackingController.trackingSttData.sttDetail];
  }

  formattedColumnNameSTT(isReverseJourney: any, isNewStt: any): string {
    if (!isReverseJourney) {
      return "Elexys";
    }
    return isNewStt ? "Sebelumnya" : "Pengganti";
  }

  get leftColumn() {
    return [
      {
        name: `No. STT ${isMigrateFromElexys() ? "Genesis" : ""}`,
        styleHead: "w-1/5 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.sttNo}</div>`;
        }
      },
      {
        name: `No. STT ${this.formattedColumnNameSTT(
          this.IS_REVERSE_JOURNEY,
          this.isNewStt
        )}`,
        styleHead: "w-1/6 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.sttNoRef}</div>`;
        }
      },
      {
        name: this.$t("trackingCargo.columnsSttDetail.colStatusPayment"),
        styleHead: "w-1/5 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          let data;

          if (!item.isPaid) {
            if (new RegExp(/^ARA|^ARB/).exec(item.shipmentId)) {
              data = {
                label: "Pembayaran COD",
                style: "bg-blue-lp-200 text-blue-lp-300"
              };
            } else {
              data = {
                label: "Kurang Bayar",
                style: "bg-yellow-lp-500 text-white"
              };
            }
          } else {
            data = {
              label: "Sudah Lunas",
              style: "bg-green-lp-700 text-white"
            };
          }

          return `<div class='text-left ${data.style} font-medium font-montserrat rounded-md px-3 py-1 whitespace-no-wrap text-12px xxl:text-14px'>${data.label}</div>`;
        }
      }
    ];
  }

  get rightColumnSplitOne() {
    return [
      {
        name: this.$t("trackingCargo.columnsSttDetail.colPieces"),
        styleHead: "koli text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.totalPieces}</div>`;
        }
      },
      {
        name: this.$t("trackingCargo.columnsSttDetail.colCurrentStatus"),
        styleHead: "w-1/6 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px rounded-full capitalize px-2 py-0 bg-gray-lp-400 font-medium'>${item.status}</div>`;
        },
        toolTip: (item: TrackingSttDetailData) => {
          return `<div class='flex text-black-lp-300'>${item.statusLabel}</div>`;
        }
      },
      {
        name: this.$t("trackingCargo.columnsSttDetail.colResponse"),
        styleHead: "w-1/6 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          return `<div class='text-left font-medium font-montserrat whitespace-no-wrap" ${
            item.rebuttalDex
              ? "bg-green-lp-300 text-green-lp-200 rounded-2xl px-3 py-1"
              : ""
          }  rounded-md text-12px xxl:text-14px'>
          ${item.rebuttalDex || "-"}</div>`;
        }
      }
    ];
  }

  get rightColumnSplitTwo() {
    return [
      {
        name: this.$t("trackingCargo.columnsSttDetail.colShipmentType"),
        styleHead: "w-3/20 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px rounded capitalize px-2 py-0 bg-gray-lp-400 font-medium'>${item.productType}</div>`;
        }
      },
      {
        name: this.$t("trackingCargo.columnsSttDetail.colShipmentTime"),
        styleHead: "w-2/12 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${
            !IS_SENDER_ACCOUNT
              ? this.convertEstimationForNonSender(item.estimationsDate)
              : this.convertEstimationDate(item.estimationsDate)
          }</div>`;
        }
      },
      {
        name: this.$t("trackingCargo.columnsSttDetail.colChargeableWeight"),
        styleHead: "w-3/12 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-none",
        render: (item: TrackingSttDetailData) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${convertDecimalWithComma(
            item.chargeableWeight,
            2
          )} Kg</div>`;
        }
      }
    ];
  }

  get columns() {
    const tempColumns = [
      ...this.leftColumn,
      ...this.rightColumnSplitOne,
      ...this.rightColumnSplitTwo
    ];

    !isMigrateFromElexys() && !IS_SENDER_ACCOUNT && tempColumns.splice(1, 1);
    this.isPaid && tempColumns.splice(2, 1);
    this.sttDetail.status !== "DEX" &&
      tempColumns.splice(this.isPaid ? 4 : 5, 1);
    return tempColumns;
  }

  pagination = {
    page: 1,
    limit: 100
  };

  get isPaid() {
    return TrackingController.trackingSttData.sttDetail.isPaid;
  }

  //table shipment history
  get shipmentHistory() {
    return this.activeMenu === "current"
      ? TrackingController.trackingSttData.sttListTracking
      : TrackingController.trackingSttSecondData.sttListTracking;
  }

  formatDateFromOverseas(item: TrackingSttListHistory) {
    const formattedDate = new Date(item.statusDate);
    let timezone = item.timezone.replace("GMT", "");
    if (timezone.length < 6) {
      timezone =
        timezone.slice(0, 1) + "0" + timezone.slice(1, timezone.length);
    }
    const result = moment(formattedDate)
      .utcOffset(timezone)
      .format("DD MMMM YYYY, HH:mm");
    return this.accountIsForeign
      ? `${dateToDateString(result, false, true, "en")} MYT`
      : `${result} ${item.timezone} (Waktu Internasional)`;
  }

  convertEstimationDate(val: any) {
    if (val === "-") return val;
    const splitDate = val.split(" - ");
    const startDate = moment(splitDate[0]).format("DD MMMM YYYY");
    const endDate = moment(splitDate[1]).format("DD MMMM YYYY");
    return `${startDate} - ${endDate}`;
  }

  convertEstimationForNonSender(estimation: string) {
    if (estimation === "-") return estimation;
    if (this.accountIsForeign) {
      return this.convertEstimationDateEnglish(estimation);
    }

    return this.convertEstimationDate(estimation);
  }

  convertEstimationDateEnglish(val: any) {
    const splitDate = val.split(" - ");
    const startDate = dateToDateString(splitDate[0], false, false, "en");
    const endDate = dateToDateString(splitDate[1], false, false, "en");
    return `${startDate} - ${endDate}`;
  }

  formattedActorNameHistory(item: any) {
    const hubName = item.hubName ? `- ${item.hubName}` : "";
    return item.actorType !== "SYSTEM"
      ? `<div class="flex text-gray-lp-500">(${item.actorType}) ${item.actorName} - ${item.createdName} ${hubName}</div>`
      : "";
  }

  get columnsHistory() {
    return [...this.columnsHistoryLeft, ...this.columnsHistoryRight];
  }

  get columnsHistoryLeft() {
    return [
      {
        name: this.$t("trackingCargo.columnHistoryStatus.colStatusDate"),
        styleHead: "w-1/5 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-t-0",
        render: (item: TrackingSttListHistory) => {
          return `<div class="overflow-ellipsis text-left whitespace-no-wrap">${
            item.countryID === "ID"
              ? formatDateTrackingHistory(
                  item.statusDate,
                  item.timezone,
                  true,
                  false
                )
              : this.formatDateFromOverseas(item)
          }</div>
          ${!IS_SENDER_ACCOUNT ? this.formattedActorNameHistory(item) : ""}`;
        }
      },
      {
        name: this.$t("trackingCargo.columnHistoryStatus.colCurrentStatus"),
        styleHead: "w-1/6 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-t-0",
        render: (item: TrackingSttListHistory) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px rounded-full capitalize px-2 py-0 bg-gray-lp-400 font-medium'>
                    ${item.status}
                  </div>`;
        },
        toolTip: (item: TrackingSttListHistory) => {
          return `<div class='flex text-12px text-black-lp-300'>${item.statusLabel}</div>`;
        }
      }
    ];
  }

  get columnsHistoryRight() {
    return [
      {
        name: this.$t("trackingCargo.columnHistoryStatus.colPieces"),
        styleHead: "w-1/12 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-t-0",
        render: (item: TrackingSttListHistory) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.totalPieces}</div>`;
        }
      },
      {
        name: this.$t("trackingCargo.columnHistoryStatus.colLocation"),
        styleHead: "w-1/6 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-t-0",
        render: (item: TrackingSttListHistory) => {
          return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.location}</div>`;
        }
      },
      {
        name: this.$t("trackingCargo.columnHistoryStatus.colRemarks"),
        styleHead: "text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "border-t-0",
        onClickValue: (data: any) => this.onClickValue(data),
        render: (item: TrackingSttListHistory) => {
          return `<div class='text-left text-black-lp-300 text-12px xxl:text-14px'>${this.styleDesc(
            item
          )}</div>`;
        }
      }
    ];
  }

  get accountIsForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  styleDesc(item: TrackingSttListHistory) {
    let description = item.description;
    if (description.includes("[")) {
      const contactLink = description.substring(
        description.indexOf("[") + 1,
        description.lastIndexOf("]")
      );

      const contactSplited = contactLink.split("-");
      const anchor = contactSplited[0];
      const destination = contactSplited[1];

      const reg = /[[\]']+/g;
      description = item.description
        .replace(reg, "")
        .replace(
          contactLink,
          `<a href=${destination} style='color: blue;' target='_blank'>${anchor}</a>`
        );
    }

    if (item.status.includes("CARGO")) {
      if (description.includes("No. AWB")) {
        return description.replace(
          /No. AWB ([^\s^,]+)/g,
          "No. AWB <a class='text-red-lp-200 underline' >$1</a>"
        );
      }
      if (description.includes("AWB No.")) {
        return description.replace(
          /AWB No. ([^\s^,]+)/g,
          "AWB No. <a class='text-red-lp-200 underline' >$1</a>"
        );
      }
      if (description.includes("No. Cargo")) {
        return description.replace(
          /No. Cargo ([^\s^,]+)/g,
          "No. Cargo <a class='text-red-lp-200 underline' >$1</a>"
        );
      }
      if (description.includes("Cargo No.")) {
        return description.replace(
          /Cargo No. ([^\s^,]+)/g,
          "Cargo No. <a class='text-red-lp-200 underline' >$1</a>"
        );
      }
      return description;
    } else if (item.status.match(/^pod$|^dex$/gi)) {
      return item.attachment.length > 0 && item.attachment[0] !== ""
        ? description +
            "</br> <a class='text-red-lp-300 hover:underline'>Lihat Bukti Pengiriman</a>"
        : description;
    } else if (item.status.match(/^bkd$|^stt adjusted$/gi)) {
      return item.sttWeightAttachFiles.length
        ? description +
            "</br> <a class='text-red-lp-300 font-semibold hover:underline'>Lihat bukti foto perubahan berat.</a>"
        : description;
    } else {
      return description;
    }
  }

  async onClickValue(data: TrackingSttListHistory) {
    if (data.status.includes("CARGO")) {
      this.onClickCargoStatus(data);
    } else if (data.status.match(/^pod$|^dex$/gi)) {
      if (data.attachment.length > 0 && data.attachment[0] !== "") {
        this.images = data.attachment;
        !!this.actionModalPreview && this.actionModalPreview(true);
      }
    } else if (data.status.match(/^bkd$|^stt adjusted$/gi)) {
      if (data.sttWeightAttachFiles.length) {
        this.images = data.sttWeightAttachFiles;
        !!this.actionModalPreview && this.actionModalPreview(true);
      }
    }
  }

  async onClickCargoStatus(data: any) {
    const regex: any = /Cargo Number ([^\s]+)|No. AWB ([^\s]+)|No. Cargo ([^\s]+)|Cargo No. ([^\s]+)|AWB No. ([^\s]+)/g.exec(
      data.description
    );
    const cargoNo =
      regex.length > 1
        ? regex[1]?.replace(",", "") ||
          regex[2]?.replace(",", "") ||
          regex[3]?.replace(",", "") ||
          regex[4]?.replace(",", "") ||
          regex[5]?.replace(",", "")
        : "";
    router.push({
      name: "tracking-cargo",
      query: { "cargo-number": cargoNo }
    });
    if (!cargoNo) return;
    this.processNGenLogin(cargoNo);
  }

  async processNGenLogin(cargoNo: string) {
    let isAuth = false;
    if (!getTokenNGen()) {
      isAuth = await NGenController.onLoginNGen(false);
    } else {
      isAuth = true;
    }
    if (isAuth) {
      await TrackingController.trackingCargo(cargoNo);
    }
  }

  actionModalPreview(boolean: boolean) {
    this.showPreviewImage = boolean;
  }

  get isShowAlertMessage() {
    return (
      this.isError &&
      (this.errorCause === "notfoundstt" || this.errorCause === "unusedstt")
    );
  }

  get alertMessage() {
    switch (this.errorCause) {
      case "notfoundstt":
        return {
          title: this.$t("trackingCargo.error.errDataNotFoundTitle"),
          message: this.$t("trackingCargo.error.errDataNotFoundMsg"),
          image: "data-not-found"
        };
      case "unusedstt":
        return {
          title: this.$t("trackingCargo.error.errUnusedTitle"),
          message: this.$t("trackingCargo.error.errUnusedMsg"),
          image: "data-not-found"
        };
      default:
        return {
          title: "",
          message: "",
          image: ""
        };
    }
  }

  refs: any = {};
  togglePanelPrint(event: any) {
    this.refs.panelPrint.toggle(event);
  }
  get isPanelPrintVisible() {
    return this.refs.panelPrint?.visible;
  }

  async print(type: string) {
    const language = this.sttDetail.productType === "INTERPACK" ? "en" : "id";
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () =>
        book.default.methods.printThermal(this.sttDetail.sttId, language),
      "thermal-pdf": () =>
        book.default.methods.printBasic(
          this.sttDetail.sttId,
          0,
          language,
          false,
          true
        ),
      basic: () =>
        book.default.methods.printBasic(this.sttDetail.sttId, 1, language)
    };

    printType[type]();
  }

  get isErrorShipmentDetail() {
    return ShipmentBookingController.isError;
  }
  get errorCauseShipmentDetail() {
    return ShipmentBookingController.isErrorCause;
  }
  get isLoadingShipmentDetail() {
    return ShipmentBookingController.isLoading;
  }
  get shipmentBookingDetail(): ShipmentBookingDetail {
    return ShipmentBookingController.shipmentBookingDetail;
  }

  get showButtonDetailTracking() {
    const accountCityCode =
      AccountController.accountData.account_location?.city_code;

    const isAvailable =
      !this.isLoadingShipmentDetail &&
      !this.isErrorShipmentDetail &&
      !this.errorCauseShipmentDetail;

    if (this.isConsole || this.isSubConsole) {
      return (
        isAvailable &&
        (this.shipmentBookingDetail.sttOriginCityId === accountCityCode ||
          this.shipmentBookingDetail.sttDestinationCityId === accountCityCode)
      );
    }
    return isAvailable;
  }

  get isConsole() {
    return AccountController.accountData.account_type_detail.type === "console";
  }
  get isSubConsole() {
    return (
      AccountController.accountData.account_type_detail.type === "sub-console"
    );
  }

  getDetailShipment() {
    ShipmentBookingController.getBookingDetail({
      id: this.sttDetail.sttId || 0,
      isAuth: !(this.isConsole || this.isSubConsole)
    });
  }

  goToDetailShipment(isConsole: boolean, isSubConsole: boolean, sttId: number) {
    if (IS_SENDER_ACCOUNT) {
      return window.open(`/lacak-pengiriman/${sttId}`, "_blank");
    }
    const result = isConsole || isSubConsole ? `on-process-stt` : `booking`;
    window.open(`/shipment/${result}/${sttId}`, "_blank");
  }

  toCreatePusatResolusi() {
    dataLayer("laporkan_kendala_tracking_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    this.$router.push(`/pusat-resolusi/create?sttNo=${this.sttDetail.sttNo}`);
  }

  get messageEmptyState() {
    return this.$t("trackingCargo.error.errMessageEmpty");
  }

  get errorHedearMessage() {
    return this.$t("trackingCargo.error.errMessageEmptyTitle");
  }
}
