
import { Vue, Options, prop } from "vue-class-component";
import { TrackingSttListHistory } from "@/domain/entities/TrackingStt";

class Props {
  item = prop<TrackingSttListHistory>({
    default: TrackingSttListHistory,
    type: TrackingSttListHistory,
  });
  time = prop<string>({
    default: "",
    type: String,
  });
  active = prop<boolean>({
    default: false,
    type: Boolean,
  });
  status = prop<string>({
    default: "",
    type: String,
  });
  transitLocation = prop<string>({
    default: "",
    type: String,
  });
  districtCity = prop<string>({
    default: "",
    type: String,
  });
  description = prop<string>({
    default: "",
    type: String,
  });
  tooltip = prop<string>({
    default: "",
    type: String,
  });
  proofPod = prop<boolean>({
    default: false,
    type: Boolean,
  });
  proofAdjustBook = prop<boolean>({
    default: false,
    type: Boolean,
  });
  internationalTime = prop<string>({
    default: "",
    type: String,
  });
  isVerticalLine = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isHalfVerticalLine = prop<boolean>({
    default: false,
    type: Boolean,
  });
}

@Options({
  name: "Timeline",
  emits: ["onClickValue"],
})
export default class extends Vue.with(Props) {
  onClickValue() {
    this.$emit("onClickValue", this.item);
  }
}
