
import { Vue, Options } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import {
  convertDecimalWithComma,
  formatDateWithoutTime,
  getFormattedDate,
} from "@/app/infrastructures/misc/Utils";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import { TrackingCargoData } from "@/domain/entities/TrackingCargo";
import { NGenController } from "@/app/ui/controllers/NGenController";
import debounce from "lodash/debounce";
import { TrackingCargoByBookingRequestIdNgenApiRequest } from "@/data/payload/api/NGenApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";

@Options({
  components: {
    EmptyState,
    Skeleton
  }
})
export default class TrackingCargo extends Vue {
  cargoNo = "";
  isErrorCargoNo = false;

  mounted() {
    TrackingController.initTrackingStt();
    this.onAuthNGen();
    const query: any = this.$route.query["cargo-number"];
    this.cargoNo = query || "";
    if (this.showCargoDetail || query) {
      this.showDetailCargo(true);
    } else {
      this.showDetailCargo(false);
    }
  }

  unmounted() {
    this.showDetailCargo(false);
  }

  showDetailCargo(action: boolean) {
    TrackingController.setShowSttDetailCargo(action);
  }

  // ngen
  async onAuthNGen() {
    const query: any = this.$route.query["cargo-number"];
    if (!query) {
      NGenController.onLoginNGen();
    }
    NGenController.onLoginNGen(false);
  }
  get errorCauseAuth() {
    return NGenController.errorCause;
  }
  get isLoadingAuth() {
    return NGenController.isLoading;
  }

  get showCargoDetail() {
    return TrackingController.showSttDetailCargo;
  }

  get detailCargo() {
    return [this.trackingCargoData];
  }

  get trackingCargoData() {
    return TrackingController.trackingCargoData;
  }

  handleErrorCargoNo() {
    TrackingController.setError(false);
  }

  trackCargo = debounce(async () => {
    if (!this.cargoNo) return;
    if (this.validateBookingRequestId(this.cargoNo)) {
      this.isErrorCargoNo = false;
      const ngenResponse = await NGenController.trackingCargoByBookingRequestIdNgen({
        payload: new TrackingCargoByBookingRequestIdNgenApiRequest(this.cargoNo)
      });
      const statusMessage = ngenResponse[0].statusMessage === "BookingRequestID –This Booking Request Id is already Accepted in nGen, kindly use AWB Tracking API" || ngenResponse[0].statusMessage === "BookingRequestID is successfully processed - status EXE"
      if (ngenResponse[0].AWBNo && statusMessage) {
        await TrackingController.trackingCargo(
          ngenResponse[0].AWBNo
        )
      } else {
        TrackingController.setShowSttDetailCargo(false);
        MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: "client-error",
              message: ngenResponse[0].statusMessage,
              title: "Tracking Failed !",
              onClose: () => {
                MainAppController.closeErrorMessage();
              }
            })
        )
      }
    } else {
      this.isErrorCargoNo = !(await TrackingController.trackingCargo(
        this.cargoNo
      ));
    }
  }, 500);

  validateBookingRequestId(val: string) {
    const pattern = /^[A-Z]{3}(--)?\d{7}(-)?\d{8}$/;
    return pattern.test(val);
  }

  get isLoading() {
    return TrackingController.isLoading;
  }

  get isError() {
    return TrackingController.isError;
  }

  get errorCause() {
    return TrackingController.errorCause;
  }

  get columnLeft() { 
    return [
    {
      name: "No. Kargo",
      styleHead:
        "w-2/20 text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: TrackingCargoData) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.cargoNo}</div>`;
      }
    },
    {
      name: this.$t("No. Seri Kendaraan"),
      styleHead:
        "w-2/12 text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: TrackingCargoData) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.cargoVehicleNo}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: TrackingCargoData) => {
        return `<div class='text-left text-black-lp-300 whitespace-nowrap text-12px xxl:text-14px rounded-full px-2 py-0 bg-gray-lp-400 font-medium capitalize'>${item.cargoHistory[0]?.status || "CARGO " + item.cargoType}</div>`;
      }
    },
    {
      name: "Total STT",
      styleHead:
        "w-9percent text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: TrackingCargoData) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.cargoTotalStt}</div>`;
      }
    },
    {
      name: this.$t("Total Koli"),
      styleHead:
        "w-9percent text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: TrackingCargoData) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.cargoTotalSttPiece}</div>`;
      }
    },
  ]}

  get columnRight() { 
    return [
    {
      name: this.$t("Total Berat <br /> Kotor"),
      styleHead:
        "w-2/20 text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: TrackingCargoData) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${convertDecimalWithComma(item.cargoTotalGrossWeight, 2)} Kg</div>`;
      }
    },
    {
      name: this.$t("Total Berat <br /> Dimensi"),
      styleHead:
        "w-3/20 text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: TrackingCargoData) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${convertDecimalWithComma(item.cargoTotalVolumeWeight, 2)} Kg</div>`;
      }
    },
    {
      name: this.$t("Total Berat <br /> Kotor Kargo"),
      styleHead: "text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: any) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${convertDecimalWithComma(item.cargoTotalCustomGrossWeight, 2)} Kg</div>`;
      }
    },
    {
      name: this.$t("Total Berat <br /> Dimensi Kargo"),
      styleHead: "text-left text-black-lp-300 whitespace-no-wrap align-top",
      styleCustom: "border-none",
      render: (item: TrackingCargoData) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${convertDecimalWithComma(item.cargoTotalCustomVolumeWeight, 2)} Kg</div>`;
      }
    }
  ]}

  get columns() {
    const tempColumn = [...this.columnLeft, ...this.columnRight];
    if (this.trackingCargoData.cargoType === "plane") {
      tempColumn.splice(2, 1);
    }
    return tempColumn;
  }

  pagination = {
    page: 1,
    limit: 100
  };

  //table shipment history
  get shipmentHistory() {
    if (this.trackingCargoData.cargoType === "plane") {
      return TrackingController.cargoHistoryNgen;
    }
    return this.trackingCargoData.cargoHistory;
  }

  get columnsHistory() {
    if (this.trackingCargoData.cargoType === "plane") {
      return this.columnCargoHistoryNgen;
    }

    return [
      ...this.columnLeftHistory,
      ...this.columnRightHistory
    ];
  }

  get columnLeftHistory() { 
    return [
    {
      name: this.$t("Tanggal Status"),
      styleHead: "w-1/5 text-left text-black-lp-300 whitespace-no-wrap",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left whitespace-no-wrap">${this.formatDateHistory(item.statusDate)}</div><div class="flex text-gray-lp-500">${item.actorName}</div>`;
      }
    },
    {
      name: this.$t("Status"),
      styleHead: "w-1/6 text-left text-black-lp-300 whitespace-no-wrap",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px rounded-full capitalize px-2 py-0 bg-gray-lp-400 font-medium'>${item.status}</div>`;
      },
      toolTip: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.statusLabel}</div>`;
      }
    },
    {
      name: "Koli",
      styleHead: "w-1/12 text-left text-black-lp-300 whitespace-no-wrap",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.totalPieces}</div>`;
      }
    },
  ]}

  get columnRightHistory() {
    return [
    {
      name: "Lokasi",
      styleHead: "w-1/6 text-left text-black-lp-300 whitespace-no-wrap",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.location}</div>`;
      }
    },
    {
      name: "Keterangan",
      styleHead: "text-left text-black-lp-300 whitespace-no-wrap",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class='text-left text-black-lp-300 text-12px xxl:text-14px'>${item.description}</div>`;
      }
    }
  ]}

  get columnCargoHistoryNgen() { 
    return [
    {
      name: this.$t("Tanggal Status"),
      styleHead:
        "align-text-top w-1/5 text-left text-black-lp-300 whitespace-no-wrap",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left whitespace-no-wrap">${this.formatDateHistory(
          item.updatedOn
        )}</div>
        <div class="flex text-gray-lp-500">${item.updatedBy}</div>`;
      }
    },
    {
      name: this.$t("Status"),
      styleHead:
        "align-text-top w-1/6 text-left text-black-lp-300 whitespace-no-wrap",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.status}</div>`;
      }
    },
    {
      name: this.$t("No. Penerbangan"),
      styleHead: "align-text-top w-1/12 text-left text-black-lp-300",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.flightNumber}</div>`;
      }
    },
    {
      name: this.$t("Tanggal Penerbangan"),
      styleHead: "align-text-top w-1/12 text-left text-black-lp-300",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left whitespace-no-wrap">${formatDateWithoutTime(
          item.flightDate
        )}</div>`;
      }
    },
    {
      name: this.$t("Estimasi Keberangkatan"),
      styleHead: "align-text-top w-1/12 text-left text-black-lp-300",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.etd ||
          "-"}</div>`;
      }
    },
    {
      name: this.$t("Estimasi Kedatangan"),
      styleHead: "align-text-top w-1/12 text-left text-black-lp-300",
      styleCustom: "border-t-0",
      render: (item: any) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px'>${item.eta ||
          "-"}</div>`;
      }
    }
  ]};

  formatDateHistory(date: any) {
    return getFormattedDate(new Date(date))
      .split(" ")
      .map((item: string, index: number) => (index === 2 ? `${item},` : item))
      .join(" ");
  }

  get isShowAlertMessage() {
    return this.isError && this.errorCause === "notfound";
  }

  get alertMessage() {
    if (this.errorCause === "notfound") {
      return {
        title: "Data Tidak Ditemukan",
        message: "Maaf, data yang Anda cari tidak ditemukan.",
        image: "data-not-found"
      };
    }
    return {
      title: "",
      message: "",
      image: ""
    };
  }

  get messageEmptyState() {
    return this.$t('trackingCargo.error.errMessageEmptyCargo');
  }

  get errorHedearMessage() {
    return this.$t('trackingCargo.error.errMessageEmptyTitle');
  }
}
