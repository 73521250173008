
import { FlagsPermissionSTTTracking } from "@/feature-flags/flags-stt-tracking";
import { Vue, Options } from "vue-class-component";
import TrackingSttV1 from "./v1/index.vue";
import TrackingSttV2 from "./v2/index.vue";

@Options({
  components: {
    TrackingSttV1,
    TrackingSttV2,
  },
})
export default class TrackingStt extends Vue {
  get isVersionNew() {
    return FlagsPermissionSTTTracking.flag_revamp_tracking.isEnabled();
  }
}
